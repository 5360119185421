import {
  AaaContact,
  AaaServiceLocation,
  FoiType,
  AaaAddressType,
  AaaContactType,
  AaaPhoneType,
  AaaAddress,
  LocationCode,
  StandardServiceCall,
  AaaCommentType,
  AaaTroubleCode,
  PriortyCode,
} from 'types/aaa'
import {
  Branch,
  Brands,
  EitherOrBoth,
  Participant,
  PartialVehicle,
  TroubleCode,
  TroubleCodeType,
  ParticipantAddress,
} from 'types/global'
import { GeocodeAddress } from 'types/googleMaps'
import { SoftService, Tow, TowExchange } from 'types/callstore'
import { isBranch, isLocation, isParticipant, isNumeric } from 'utils/typeChecks'
import { BreakdownOptions } from 'forms/CallForm/BreakdownDetails/BreakdownRadio'
import { isEmpty, isNil, uniqWith } from 'lodash'
import { LocationPayload, ServiceLocationType } from 'types/location'

const TroubleCodeDescriptions = {
  [TroubleCode.T1]: 'Flat w/Spare',
  [TroubleCode.T2]: 'Flat No Spare',
  [TroubleCode.T3]: 'Battery',
  [TroubleCode.T4]: "Can't Start",
  [TroubleCode.T5]: 'Fuel',
  [TroubleCode.T6]: 'Tow',
  [TroubleCode.T7]: 'Lockout',
  [TroubleCode.T8]: 'Extrication (Winch)',
  [TroubleCode.T9]: 'Miscellaneous',
  [TroubleCode.T0]: 'Gone on arrival',
  [TroubleCode.A7]: 'Lockout - Key Delivery',
  [TroubleCode.B7]: 'Lockout - Locksmith',
}
const PriorityCodeDescription = {
  P1: 'P1 - BABY/ANIMAL LOCKED IN CAR',
  P2: 'P2 - URGENT SAFETY ISSUE',
  P3: 'P3 - EXTREME WEATHER/NO SHELTER',
  P4: 'P4 - PUBLIC AGENCY/POLICE ON SCENE',
  P5: 'P5 - BLOCKING TRAFFIC',
  P6: 'P6 - ACCIDENT',
  P7: 'P7 - EXPRESSWAY CALL',
  P8: 'P8 - LOCKOUT CAR RUNNING',
  R1: 'R1 - AAA EMPLOYEE/VIP',
  R2: 'R2 - AAA ERROR/RE-DISPATCH CALL',
}

const defaultParticipantAddress: ParticipantAddress = {
  addressLine1: '',
  city: '',
  administrativeArea: '',
  postalCode: '',
}

export type LocationPayloadParticipant = EitherOrBoth<LocationPayload, Participant>

export const createSoftService = (
  standard: StandardServiceCall,
  softService: SoftService,
  troubleCode: TroubleCode,
): StandardServiceCall => {
  const { vehicle, serviceLocations, notes, breakdownOption, customVin } = softService

  const customVinVehicle = {
    year: new Date().getFullYear(), // Set vehicle year to current year
    make: 'N/A',
    model: 'N/A',
    licensePlate: 'N/A',
    licensePlateState: 'N/A',
    vin: customVin,
    color: '#000000',
  }

  let request = standard
  request = customVin ? addVehicle(request, customVinVehicle as PartialVehicle) : addVehicle(request, vehicle as PartialVehicle)
  const vehicleLocation = serviceLocations.vehicleLocation
  if (vehicleLocation) request = addServiceLocation(request, vehicleLocation)

  if (breakdownOption === BreakdownOptions.ROADWAYINTERSTATE || breakdownOption === BreakdownOptions.PARKINGGARAGE) {
    request = addComment(request, breakdownOption)
  }
  request = addSplitComments(request, notes)

  if (softService.breakdownOption === BreakdownOptions.ROADWAYINTERSTATE) {
    request = addPriorityCode(request, PriortyCode.P7)
    request = addComment(request, PriorityCodeDescription.P7)
    request = addLocationCode(request, LocationCode.RD)
  }
  if (softService.breakdownOption === BreakdownOptions.PARKINGGARAGE) {
    request = addLocationCode(request, LocationCode.PS)
  }
  request = addService(request, {
    code: troubleCode,
    description: TroubleCodeDescriptions[troubleCode],
    troubleCodeType: TroubleCodeType.PROBLEM,
  })
  return request
}

export const createTowService = (
  standard: StandardServiceCall,
  tow: Tow | TowExchange,
  troubleCode: TroubleCode,
): StandardServiceCall => {
  const { vehicle, vehicleCondition, serviceLocations, notes, customVin } = tow
  const requirePhotosComment = 'PHOTOS REQUIRED'

  const formattedBusinessHours = formatBusinessHours(serviceLocations.vehicleDestinationLocation as unknown as Participant)

  const customVinVehicle = {
    year: new Date().getFullYear(), // Set vehicle year to current year
    make: 'N/A',
    model: 'N/A',
    licensePlate: 'N/A',
    licensePlateState: 'N/A',
    vin: customVin,
    color: '#000000',
  }

  let request = standard
  let locationValues = Object.values(serviceLocations)
  request = customVin
    ? addVehicle(request, customVinVehicle as PartialVehicle, vehicleCondition)
    : addVehicle(request, vehicle as PartialVehicle, vehicleCondition)
  // This will filter out "serviceLocations" that are the exact same duplicate. Handles use case of failing
  // AAA dispatch if Caller & Vehicle are the exact same payload
  if (locationValues.length === 3)
    locationValues = uniqWith(locationValues, (a, b) => a?.latitude === b?.latitude && a?.longitude === b?.longitude)

  request = locationValues.reduce(addServiceLocation, request)

  if (tow.breakdownOption === BreakdownOptions.ROADWAYINTERSTATE || tow.breakdownOption === BreakdownOptions.PARKINGGARAGE) {
    request = addComment(request, tow.breakdownOption)
  }
  request = addSplitComments(request, notes)

  request = addComment(request, requirePhotosComment)
  if (vehicleCondition === 'Accident/Collision/Vandalism Tow') {
    request = addPriorityCode(request, PriortyCode.P6)
    request = addComment(request, PriorityCodeDescription.P6)
  }
  if (tow.breakdownOption === BreakdownOptions.ROADWAYINTERSTATE) {
    request = addPriorityCode(request, PriortyCode.P7)
    request = addComment(request, PriorityCodeDescription.P7)
    request = addLocationCode(request, LocationCode.RD)
  }
  if (tow.breakdownOption === BreakdownOptions.PARKINGGARAGE) {
    request = addLocationCode(request, LocationCode.PS)
  }
  request = addService(request, {
    code: troubleCode,
    description: TroubleCodeDescriptions[troubleCode],
    troubleCodeType: TroubleCodeType.PROBLEM,
  })

  // Change program code (aka org code) for Tow Exchange with 3 locations
  if (Object.entries(serviceLocations).length === 3 && serviceLocations?.exchangeLocation) {
    const vehicleDestLocName = serviceLocations.vehicleDestinationLocation?.entity?.name || ''
    const vehicleDestLocAddress = serviceLocations.vehicleDestinationLocation?.entity?.address || ''
    const vehicleDestLocAddressString = `${(vehicleDestLocAddress as ParticipantAddress)?.addressLine1}, ${(vehicleDestLocAddress as ParticipantAddress)?.city}, ${(vehicleDestLocAddress as ParticipantAddress)?.administrativeArea} ${(vehicleDestLocAddress as ParticipantAddress)?.postalCode}`
    request = addSplitComments(
      request,
      `TOW EXCHANGE - P/U DISABLED VEHICLE AND TOW TO - ${vehicleDestLocName} - ${vehicleDestLocAddressString}`,
    )
    request = {
      ...request,
      customer: {
        ...request.customer,
        organization: {
          code: TowExchangeProgramCodes[request.customer.organization!.name as Brands],
          name: TowExchangeProgramNames[request.customer.organization!.name as Brands],
        },
      },
    }
  }

  request = addRideAlong(request, tow)
  request = addUnattended(request, tow)

  if (formattedBusinessHours !== '') request = addSplitComments(request, 'Destination Business Hours: ' + formattedBusinessHours)
  else request = addComment(request, 'Destination Business Hours Unavailable.')

  return request
}

export const addUnattended = (standard: StandardServiceCall, tow: Tow | TowExchange) => {
  if (tow.unattended) {
    const { vehicle, vehicleCondition, unattended, keysLocation, keysLocationOther } = tow
    return addVehicle(
      standard,
      {
        ...(vehicle as PartialVehicle),
        unattended,
        unattendedNotes: `KEYS: ${keysLocationOther || keysLocation!.label}`,
      },
      vehicleCondition,
    )
  }
  return standard
}

export const addRideAlong = (standard: StandardServiceCall, tow: Tow | TowExchange) => {
  let request = standard
  if (tow.rideAlong) {
    request = {
      ...request,
      service: {
        ...request.service,
        rideAlongRequested: true,
      },
    }

    if (tow.rideAlongLocation) {
      // strip html
      const html = tow.rideAlongLocation
      const address = html.replace(/<br\s*\/?>/gi, ', ')

      request = {
        ...request,
        service: {
          ...request.service,
          rideAlongNotes: address.substring(0, 180),
        },
      }
    }
  }

  return request
}

// Modify StandardServiceCall functions

export const addService = (request: StandardServiceCall, service: AaaTroubleCode): StandardServiceCall => ({
  ...request,
  service: {
    ...request.service,
    requestedServices: [...request.service.requestedServices, service],
  },
})

const formatAddress = (
  address: string,
  city: string,
  state: string,
  postalCode: string,
  country: string,
  streetNumber?: string,
): Partial<AaaAddress> => {
  let street: string = address
  postalCode = postalCode.split('-')[0]
  if (!streetNumber) {
    const [houseNumber, ...rest] = address && address.split(' ')
    if (isNumeric(houseNumber)) {
      streetNumber = houseNumber
      street = rest.join(' ')
    } else {
      street = address
    }
  }

  return {
    ...(isNumeric(streetNumber) && { streetNumber }),
    street,
    city,
    state,
    postalCode,
    country,
  }
}

const formatBusinessHours = (location: Participant) => {
  let hours = ''
  let formattedHours = ''

  if (isNil(location)) return ''

  if (location.businessHours && !isEmpty(location.businessHours.businessHoursOrdered)) {
    hours = location.businessHours.businessHoursOrdered
  } else if (location.businessHours && !isEmpty(location.businessHours.towHoursOrdered)) {
    hours = location.businessHours.towHoursOrdered
  }

  if (!isEmpty(hours) && typeof hours === 'object')
    for (const [key, value] of Object.entries(hours)) {
      formattedHours += `${key}: ${value} `
    }
  return formattedHours
}

export const locationToAAALocation = (location?: LocationPayloadParticipant | null) => {
  // Network Manager
  if (isParticipant(location)) {
    const participantLocation = location as Participant & LocationPayload
    const { addressLine1, city, administrativeArea, postalCode, country } = participantLocation.entity.address!
    const { latitude, longitude } = participantLocation.location!
    const { primaryPhone } = participantLocation.entity.contactInfo!

    return {
      serviceLocationType: ServiceLocationType.TOW_DESTINATION,
      foi: {
        foiType: FoiType.AAR,
        name: participantLocation.entity.name as string | undefined,
        location: {
          address: {
            addressType: AaaAddressType.PHYSICAL,
            ...formatAddress(addressLine1!, city!, administrativeArea!, postalCode!, country!),
          },
          latitude,
          longitude,
        },
        ...(primaryPhone && {
          contacts: [
            {
              contactType: AaaContactType.FACILITY,
              phones: [
                {
                  phoneType: AaaPhoneType.BUSINESS,
                  phoneNumber: primaryPhone.replace(/\D/g, ''),
                },
              ],
            },
          ],
        }),
      },
    }
  }

  // Breakdown
  if (isLocation(location)) {
    const breakdownLocation = location as LocationPayload
    let extendedAddress = breakdownLocation.addressDescription

    return {
      serviceLocationType: ServiceLocationType.BREAKDOWN,
      foi: {
        name: breakdownLocation.serviceLocationType,
        foiType: FoiType.ADDRESS,
        location: {
          address: {
            addressType: AaaAddressType.PHYSICAL,
            extendedAddress,
            ...location.address,
          },
          latitude: breakdownLocation.latitude,
          longitude: breakdownLocation.longitude,
        },
      },
    }
  }

  // Rental Branch
  if (isBranch(location)) {
    const branchLocation = location as Branch
    const { street_addresses, city, postal, country_code, country_subdivision_code: state } = branchLocation.address
    return {
      serviceLocationType: ServiceLocationType.RENTAL_PICKUP,
      foi: {
        foiType: FoiType.RENTAL,
        name: branchLocation.brand,
        id: branchLocation.additional_data.group_branch_number,
        location: {
          address: {
            addressType: AaaAddressType.PHYSICAL,
            ...formatAddress(street_addresses[0], city, state, postal, country_code),
          },
          latitude: parseFloat(branchLocation.gps.latitude.toString()),
          longitude: parseFloat(branchLocation.gps.longitude.toString()),
        },
        contacts: [
          {
            contactType: AaaContactType.AGENT,
            phones: [
              {
                phoneType: AaaPhoneType.BUSINESS,
                phoneNumber: branchLocation.additional_data.formatted_phone.replace(/\D/g, '').substr(1),
              },
            ],
          },
        ],
      },
    }
  }
}

export const addServiceLocation = (
  request: StandardServiceCall,
  location: LocationPayloadParticipant | null,
): StandardServiceCall => {
  if (isNil(location)) return request
  const serviceLocations = request.service.serviceLocations as Array<AaaServiceLocation>
  const loc = locationToAAALocation(location)
  if (loc) serviceLocations.push(loc)
  return request
}

export const addSplitComments = (request: StandardServiceCall, comment: string) => {
  //split comments(including whitespace/escaped characters) into 240 character chunks taking whole words into account
  //regex uses the number 238 but this accounts for leading and trailing white space
  if (isNil(comment)) return { ...request }
  if (isEmpty(comment)) return { ...request }
  let splitComments = comment.match(/\S.(.|[\r\n]){0,238}(?<=\S)(?!\S)/g)

  //send each split comment as a separate comment in the payload
  splitComments?.forEach((comment) => {
    request = addComment(request, comment)
  })

  return request
}

export const addComment = (request: StandardServiceCall, comment: string): StandardServiceCall => {
  if (isNil(comment)) return { ...request }
  if (isEmpty(comment)) return { ...request }

  return {
    ...request,
    comments: [
      ...request.comments,
      {
        commentType: AaaCommentType.NORMAL,
        text: comment,
      },
    ],
  }
}

export const addPriorityCode = (request: StandardServiceCall, inputCode: string) => {
  if (request.service.priorityCode && request.service.priorityCode.substring(1, 2) < inputCode.substring(1, 2))
    return {
      ...request,
      service: {
        ...request.service,
        priorityCode: request.service.priorityCode,
      },
    }
  else
    return {
      ...request,
      service: {
        ...request.service,
        priorityCode: inputCode,
      },
    }
}

export const addLocationCode = (request: StandardServiceCall, inputCode: LocationCode) => {
  let serviceLocation = request.service.serviceLocations[0]

  return {
    ...request,
    service: {
      ...request.service,
      serviceLocations: [
        {
          ...serviceLocation,
          foi: {
            ...serviceLocation.foi,
            location: {
              ...serviceLocation.foi.location,
              locationCode: inputCode,
            },
          },
        },
        ...request.service.serviceLocations.slice(1),
      ],
    },
  }
}

export const addCustomerContact = (request: StandardServiceCall, contact: AaaContact): StandardServiceCall => ({
  ...request,
  customer: {
    ...request.customer,
    contact,
  },
})

export const addVehicle = (
  request: StandardServiceCall,
  vehicle: PartialVehicle & { unattended?: boolean; unattendedNotes?: string },
  vehicleCondition?: string,
) => {
  const { color, year, make, model, vin, licensePlate, licensePlateState, unattended = false, unattendedNotes } = vehicle
  return {
    ...request,
    vehicle: {
      ...request.vehicle,
      // AAA dispatch has a max length for this color field
      color: isNil(color) || isEmpty(color) ? 'unknown' : color.length > 8 ? color.substring(0, 8) : color,
      year,
      make,
      model,
      vin,
      tag: licensePlate,
      state: licensePlateState,
      unattended,
      unattendedNotes,
      condition: vehicleCondition,
    },
  }
}

export const transformExtendedAddress = (address: GeocodeAddress): Partial<AaaAddress> => ({
  streetNumber: address.HouseNumber,
  street: address.Street,
  city: address.City,
  postalCode: address.PostalCode,
  state: address.State,
  country: address.Country,
})

const TowExchangeProgramCodes = {
  [Brands.ALAMO]: '932',
  [Brands.ENTERPRISE]: '930',
  [Brands.NATIONAL]: '931',
}

const TowExchangeProgramNames = {
  [Brands.ALAMO]: 'ALAMO TOW EXCHANGE',
  [Brands.ENTERPRISE]: 'ENTERPRISE TOW EXCHANGE',
  [Brands.NATIONAL]: 'NATIONAL TOW EXCHANGE',
}
